import React from "react";
import styled from "styled-components";
import vid from "../Assets/vid.png";
import { useState } from "react";
import vdx from "../Vdx/myvid.mp4";
import pic1 from "../Assets/kiva5.png";
import pic2 from "../Assets/twitter4.jpeg";
import pic3 from "../Assets/Redfin1.png";
import pic4 from "../Assets/round.jpeg";
import pic5 from "../Assets/tangle2.png";
import pic6 from "../Assets/cast3.png";

const Video = () => {
  const [show, setShow] = useState<Boolean>(false);
  const Start = () => {
    setShow(!show);
  };
  const Dstart = () => {
    setShow(!show);
  };
  return (
    <Container>
      <h3>Join the teams that achieve the impossible every day</h3>
      <Play>
        <Vid onClick={Start} src={vid} />
        <p>Watch a customer story</p>
      </Play>

      {show ? (
        <Videobg>
          <VideoHold>
            <video src={vdx} autoPlay loop></video>
          </VideoHold>
          <Close>
            <p onClick={Dstart}>close</p>
          </Close>
        </Videobg>
      ) : null}

      <Ico>
        <Kiva src={pic1} />
        <Twit src={pic2} />
        <Redf src={pic3} />
        <Nasa src={pic4} />
        <Tang src={pic5} />
        <Cast src={pic6} />
      </Ico>
    </Container>
  );
};

export default Video;

const Container = styled.div`
  width: 100%;
  height: 350px;
  background-image: linear-gradient(
    180deg,
    rgba(251, 251, 251, 0) 0.05%,
    #deebff 99.95%
  );
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 70px;

  h3 {
    color: #3c5a99;
    margin: 0;
  }
`;
const Myvd = styled.div``;

const Play = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 18px;
    color: #0065ff;
    margin-left: 10px;
  }
  padding-bottom: 40px;
`;
const Tex = styled.div``;
const Ico = styled.div`
  width: 90%;
  height: 80px;
  /* background-color: red; */
  display: flex;
  justify-content: space-around;
`;
const Vid = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const Videobg = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #0000007f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  position: fixed;
`;
const VideoHold = styled.div`
  width: 100%;
  height: 500px;
  Video {
    width: 100%;
    height: 100%;
  }
`;

const Close = styled.div`
  width: 100%;
  height: 500px;
  p {
    height: 50px;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
`;
const Kiva = styled.img``;
const Twit = styled.img``;
const Redf = styled.img``;
const Tang = styled.img``;
const Nasa = styled.img``;
const Cast = styled.img``;
