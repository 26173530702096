import React from "react";
import Header from "./Components2/Header/Header";
// import Body from "./Components/Body";
// import { Router } from "react-router-dom";
import HomeScreen from "./Components2/HomeScreen";
import Footer from "./Components2/Footer/Footer";

import Invite from "./Invite/Invite";

const App = () => {
  return (
    <div>
      <Header />
      <HomeScreen />
      <Footer />
      {/* <Invite /> */}
    </div>
  );
};

export default App;
