import React from "react";

import styled from "styled-components";
interface Iprops {
  wd: string;
  ht: string;
  text: string;
}

const Button: React.FC<Iprops> = ({ wd, ht, text }) => {
  return (
    <Container>
      <Wrapper wd={wd} ht={ht}>
        {text}
      </Wrapper>
    </Container>
  );
};

export default Button;

const Container = styled.div``;
const Wrapper = styled.div<{ wd: string; ht: string }>`
  height: ${(props) => props.ht};
  width: ${(props) => props.wd};
  background-color: blue;
  border-radius: 5px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;

  :hover {
    transition: all 350ms;
    color: blue;
    background-color: white;
    border: 1px solid blue;
    cursor: pointer;
  }
`;
