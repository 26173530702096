import React from "react";
import styled from "styled-components";
import pic from "../Assets/lady.jpg";

const Expgirl = () => {
  return (
    <Container>
      <Wrapper>
        <Explore>
          <Top>TEAM ESSENTIALS</Top>
          <Low>
            <One>Explore resources to</One>
            <Two>unleash the potential</Two>
            <Three>of your team</Three>
          </Low>
        </Explore>
        <Eplpic>
          <Lady src={pic} />
          <Together>
            <Commmunity>Atlassian Community</Commmunity>
            <Connect>
              Connect globally and meet locally to get more out of our products.
            </Connect>
            <Last>
              <Search>Search the forum</Search>
              <Join>Join our community</Join>
            </Last>
          </Together>
        </Eplpic>
      </Wrapper>
    </Container>
  );
};

export default Expgirl;

const Container = styled.div`
  width: 100%;
  height: 500px;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 90%;
  height: 100%;
  //   background-color: green;
  display: flex;
  justify-content: space-between;
`;

const Explore = styled.div`
  width: 400px;
  height: 50%;
`;

const Top = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #17a5cc;
  padding-bottom: 20px;
  margin-top: 50px;
`;
const Low = styled.div`
  width: 100%;
  height: 200px;
  //   background-color: blue;
`;
const One = styled.div`
  color: #253858;
  font-size: 40px;
  padding-bottom: 7px;
  font-weight: 500;
`;
const Two = styled.div`
  color: #253858;
  font-size: 40px;
  padding-bottom: 7px;
  font-weight: 500;
`;
const Three = styled.div`
  color: #253858;
  font-size: 40px;
  padding-bottom: 7px;
  font-weight: 500;
`;

const Eplpic = styled.div`
  width: 750px;
  height: 85%;
  background-color: #ffebe5;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  :hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: all 350ms;
  }
`;

const Lady = styled.img`
  height: 60%;
  width: 100%;
  border-radius: 0px 8px;
`;

const Commmunity = styled.div`
  font-weight: 500;
  font-size: 23px;
  color: #576278;
  padding-bottom: 14px;
`;
const Connect = styled.div`
  color: #414e67;
  font-size: 15px;
  padding-bottom: 20px;
`;
const Last = styled.div`
  height: 20px;
  width: 400px;
  display: flex;
  text-align: center;
  margin-left: 200px;

  display: flex;
  justify-content: space-between;
`;

const Together = styled.div`
  height: 300px;
  width: 100%;
  text-align: center;
`;
const Search = styled.div`
  color: #414e67;
  cursor: pointer;
  :hover {
    color: blue;
  }
`;
const Join = styled.div`
  color: #414e67;
  cursor: pointer;
  :hover {
    color: blue;
  }
`;

// import React from "react";
// import styled from "styled-components";

// import Logopic from "../Assets/logopic.webp";
// import { RiArrowDropDownLine } from "react-icons/ri";
// import { BiSearch } from "react-icons/bi";
// import { IoMdContact } from "react-icons/io";
// import Button from "../GlobalButton/Button";

// const HomeHeader = () => {
//   return (
//     <Container>
//       <Wrapper>
//         <FirstHalf>
//           <NavHolder>
//             <Logo src={Logopic} />
//             <Pro>
//               Products
//               <Ics>
//                 <RiArrowDropDownLine />
//               </Ics>
//             </Pro>
//             <Pro>
//               For Teams
//               <Ics>
//                 <RiArrowDropDownLine />
//               </Ics>
//             </Pro>
//             <Pro>
//               Support
//               <Ics>
//                 <RiArrowDropDownLine />
//               </Ics>
//             </Pro>
//           </NavHolder>
//         </FirstHalf>
//         <SecondHalf>
//           <Button wd="100px" ht="35px" text="Try now" />
//           <Buy>
//             Buy now
//             <Search>
//               <BiSearch />
//             </Search>
//           </Buy>
//           <Account>
//             <IcsAccount>
//               <IoMdContact />
//             </IcsAccount>
//             My account
//             <Ics>
//               <RiArrowDropDownLine />
//             </Ics>
//           </Account>
//         </SecondHalf>
//       </Wrapper>
//     </Container>
//   );
// };

// export default HomeHeader;

// const Container = styled.div`
//   /* background-color: blue; */
//   justify-content: center;
//   /* align-items: center; */
//   display: flex;
// `;
// const Wrapper = styled.div`
//   height: 10%;
//   width: 90%;
//   /* background-color: red; */
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;
// const FirstHalf = styled.div`
//   align-items: center;
//   display: flex;
//   justify-content: center;
//   width: 500px;
//   height: 80px;
//   /* background-color: yellow; */
// `;

// const Pro = styled.div`
//   display: flex;
// `;
// const Ics = styled.div`
//   /* width: 10px;
//   height: 10px; */
//   font-size: 15px;
//   margin-top: 3px;
// `;
// const SecondHalf = styled.div`
//   width: 400px;
//   height: 80px;
//   /* background-color: purple; */
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `;

// const Buy = styled.div`
//   display: flex;
//   width: 100px;
//   height: 50px;
//   justify-content: space-between;
//   align-items: center;
//   color: blue;
//   font-size: 13px;
// `;

// const Search = styled.div`
//   width: 30px;
//   height: 20px;
//   font-size: 25px;

//   margin-bottom: 3px;
//   color: grey;
// `;

// const Account = styled.div`
//   display: flex;
//   color: blue;
//   font-weight: Bold;
//   width: 160px;
//   height: 30px;
//   align-items: center;
//   justify-content: center;
//   border-left: 1px solid grey;
//   font-size: 15px;
// `;

// const IcsAccount = styled.div`
//   margin-top: 2px;
//   color: grey;
//   font-size: 20px;
//   margin-right: 5px;
// `;

// const Logo = styled.img`
//   height: 28px;
//   width: 200px;
// `;

// const NavHolder = styled.div`
//   width: 100%;
//   /* background-color: green; */
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `;
