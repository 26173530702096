import React from "react";
import Hero from "./Hero/Hero";
import Solution from "./Solutions/Solution";
import Alone from "./CantAlone/Alone";
import Video from "./Boxes/Video";
import Expgirl from "./Explore/Expgirl";
import Life from "./Agile/Life";
import Event from "./Agile/Events";

const HomeScreen = () => {
  return (
    <div>
      <Hero />
      <Solution />
      <Video />
      <Expgirl />
      <Life />
      <Event />
      <Alone />
    </div>
  );
};

export default HomeScreen;
