import React from "react";
import styled from "styled-components";
import pic from "../Assets/home page.png";
// import pic2 from "../asset/careers-image.png";
import pict from "../Assets/bgimage.jpeg";

const Hero = () => {
  return (
    <Container>
      <First>
        <Text>
          <h1>
            It’s possible <br /> <span> with teamwork</span>
          </h1>
        </Text>
      </First>
      <Second>
        <BgImage src={pic} />
      </Second>
    </Container>
  );
};

export default Hero;

const First = styled.div`
  margin-left: 20px;
`;
const Second = styled.div``;
const BgImage = styled.img`
  height: 450px;
`;
const Text = styled.div`
  font-size: 30px;
  font-weight: 40px;
  line-height: 1.05;
  color: #253858;
  span {
    margin-left: 50px;
  }
`;

const Container = styled.div`
  background-image: url(${pict});
  width: 100%;
  height: 500px;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;
