import React from "react";
import styled from "styled-components";
import pics from "../Assets/sidepic.jpeg";
import pic2 from "../Assets/lastpic.jpeg";
import Button from "../GlobalButton/Button";

const Alone = () => {
  return (
    <Container>
      <Wrapper>
        <ImgOne src={pics} />

        <Txt>
          <Career>CAREERS</Career>
          <We>We can’t do it alone</We>
          <LongTxt>
            We have an ambitious road ahead, and we’re looking for people to
            join
            <br />
            our global team to help shape the future of Atlassian.
          </LongTxt>
          <Button wd="150px" ht="40px" text="Join the team" />
        </Txt>
        <ImgTwo src={pic2} />
      </Wrapper>
    </Container>
  );
};

export default Alone;

const Container = styled.div``;
const Wrapper = styled.div`
  width: 100%;
  height: 300px;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ImgOne = styled.img`
  height: 100%;
  width: 300px;
`;
const Txt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Career = styled.div`
  color: #008da6;
  font-weight: bold;
  padding-bottom: 20px;
`;
const We = styled.div`
  color: #253858;
  font-weight: 500;
  font-size: 40px;
  padding-bottom: 10px;
`;
const LongTxt = styled.div`
  font-size: 18px;
  color: #42526e;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
`;

const ImgTwo = styled.img`
  height: 100%;
  width: 300px;
`;
