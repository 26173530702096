import React from "react";
import styled from "styled-components";
import { BiGlobe } from "react-icons/bi";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiOutlineCopyright } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import logipic from "../Assets/logopic.webp";

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <Up>
          <One src={logipic} />

          <Two>
            <Top>PRODUCTS</Top>
            <Middle>
              <Jira> Jira Software </Jira>
              <Align>Jira Align</Align>
              <Mag>Jira Service Management</Mag>
              <Con>Confluence Trello Bitbucket</Con>
              <Tri>Trello</Tri>
              <Bit>Bitbucket</Bit>
            </Middle>
            <Down>Veiw all products</Down>
          </Two>
          <Three>
            <Top>RESOURCES</Top>
            <Middle>
              <Jira> Technical Support </Jira>
              <Align>Purchasing & licensing</Align>
              <Mag>Atlassian Commmunity</Mag>
              <Con>Knwoledge base</Con>
              <Tri>Marketplace</Tri>
              <Bit>My Account</Bit>
            </Middle>
            <Down> Create support ticket</Down>
          </Three>
          <Four>
            <Top>EXPAND & LEARN</Top>
            <Middle>
              <Jira> Partners </Jira>
              <Align>Training & Certification</Align>
              <Mag>Documentation</Mag>
              <Con>Developer Resources</Con>
              <Tri>Enterprise</Tri>
            </Middle>
            <Down>Veiw all resources</Down>
          </Four>
          <Four>
            <Top>ABOUT ATLASSIAN</Top>
            <Middle>
              <Jira> Company </Jira>
              <Align>Courses</Align>
              <Mag>Events</Mag>
              <Con>Blogs</Con>
              <Tri>Investors Relations</Tri>
              <Bit>Trust & Security</Bit>
            </Middle>
            <Down>Contact us</Down>
          </Four>
        </Up>
        <Beneath>
          <First>
            <Eng>
              <Ics>
                <BiGlobe />
              </Ics>
              English
              <Drp>
                <IoMdArrowDropdown />
              </Drp>
            </Eng>
            <Priv>Privacy policy</Priv>
            <Tems>Terms</Tems>
            <Mord>Mordern Slavery Act</Mord>
            <Imp>Impressum</Imp>
          </First>
          <Second>
            Copyright
            <CIcs>
              <AiOutlineCopyright />
            </CIcs>
            Atlassian
          </Second>
          <Third>
            <Fb>
              <AiFillFacebook />
            </Fb>
            <Tw>
              <BsTwitter />
            </Tw>
            <Ln>
              <BsLinkedin />
            </Ln>
            <You>
              <TiSocialYoutubeCircular />
            </You>
          </Third>
        </Beneath>
      </Wrapper>
    </Container>
  );
};

export default Footer;

const Container = styled.div``;

const Wrapper = styled.div`
  height: 400px;
  width: 100%;
  background-color: lightgrey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Up = styled.div`
  height: 350px;
  width: 80%;
  /* background-color: green; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

const Top = styled.div`
  cursor: pointer;
  font-weight: bold;
  color: #253858;
  margin-top: 15px;
`;
const Middle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Down = styled.div`
  padding-top: 10px;
  color: blue;
  cursor: pointer;
`;

const One = styled.img`
  /* background-color: grey; */
  width: 160px;
  height: 15px;
  margin-top: 20px;
  cursor: pointer;
`;

const Jira = styled.div`
  padding-top: 10px;
  cursor: pointer;
  color: #253858;
  font-size: 13px;
  font-weight: 40px;
`;
const Align = styled.div`
  padding-top: 10px;
  cursor: pointer;
  color: #253858;
  font-size: 13px;
  font-weight: 40px;
`;
const Mag = styled.div`
  padding-top: 10px;
  cursor: pointer;
  color: #253858;
  font-size: 13px;
  font-weight: 40px;
`;
const Con = styled.div`
  padding-top: 10px;
  cursor: pointer;
  color: #253858;
  font-size: 13px;
  font-weight: 40px;
`;
const Tri = styled.div`
  padding-top: 10px;
  cursor: pointer;
  color: #253858;
  font-size: 13px;
  font-weight: 40px;
`;
const Bit = styled.div`
  padding-top: 10px;
  cursor: pointer;
  color: #253858;
  font-size: 13px;
  font-weight: 40px;
`;

const Two = styled.div`
  /* background-color: yellow; */
`;
const Three = styled.div`
  /* background-color: aqua; */
`;
const Four = styled.div`
  /* background-color: white; */
`;

const Beneath = styled.div`
  height: 60px;
  width: 82%;
  /* background-color: yellow; */
  display: flex;
  justify-content: space-between;
`;

const First = styled.div`
  width: 600px;
  /* height: 100%; */
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
`;

const Eng = styled.div`
  display: flex;
  color: #253858;
`;

const Ics = styled.div`
  margin-top: 2px;
`;

const Drp = styled.div`
  margin-top: 2px;
`;

const Priv = styled.div`
  color: #253858;
`;
const Tems = styled.div`
  color: #253858;
`;
const Mord = styled.div`
  color: #253858;
`;
const Imp = styled.div`
  color: #253858;
`;

const Second = styled.div`
  /* height: 100%; */
  width: 200px;
  /* background-color: grey; */
  display: flex;
  color: #253858;
`;

const CIcs = styled.div`
  margin-top: 3px;
`;

const Third = styled.div`
  /* height: 200%; */
  width: 200px;
  /* background-color: aqua; */
  display: flex;
`;

const Fb = styled.div`
  font-size: 35px;
  color: #3c5a99;
`;
const Tw = styled.div`
  font-size: 30px;
  color: #60a9dc;
`;
const Ln = styled.div`
  font-size: 30px;
  color: #016699;
`;
const You = styled.div`
  font-size: 40px;
  color: #42526c;
  padding-bottom: 2px;
`;
